<template>
  <b-tabs
    content-class="col-12 col-md-12 mt-12 mt-md-0"
    pills
    nav-wrapper-class="col-md-12 col-12"
    nav-class="nav-left"
  >

    <!-- general tab -->
    <b-tab active>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="UserIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">
          {{ $t('General') }}
          </span>
      </template>

      <order-promotion-general />
    </b-tab>
    <!--/ general tab -->

    <!-- details tab -->
    <b-tab v-if="isUpdate" >

      <!-- title -->
      <template #title>
        <feather-icon
          icon="UserIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">
          {{ $t('Detail') }}
          </span>
      </template>

      <order-promotion-details />
    </b-tab>
    <!--/ details tab -->

  </b-tabs>
</template>

<script>
import OrderPromotionGeneral from './OrderPromotionGeneral.vue'
import OrderPromotionDetails from './OrderPromotionDetails.vue'

export default {
  components: {
    OrderPromotionGeneral,
    OrderPromotionDetails,
  },
  data() {
    return {
      isUpdate: false,
    }
  },
  created() {
    if (this.$router.currentRoute.params.id > 0) {
      this.isUpdate = true;
    }
  },
}
</script>
