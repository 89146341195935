<template>
  <b-card>
    <!-- form -->
    <validation-observer ref="simpleRules">
      <b-form class="mt-2">
        <b-row>
          <b-col cols="12" md="12" lg="12">
            <b-row>
              <b-col cols="12" md="6" lg="6">
                <b-form-group :label="$t('Code')" label-for="promotionCode">
                  <validation-provider #default="{ errors }" rules="required" name="promotionCode">
                    <b-form-input id="promotionCode" v-model="itemData.promotionCode" :state="errors.length > 0 ? false:null" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12" md="6" lg="6">
                <b-form-group
                  label=""
                  label-for="contact-options"
                  label-class="mb-1">
                  <div class="demo-inline-spacing">
                    <b-form-checkbox
                      v-model="itemData.isActive"
                      class="custom-control-primary">
                      {{ $t('Active')}}
                    </b-form-checkbox>
                  </div>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12" md="6" class="mb-md-0 mb-2">
                <b-form-group
                  :label="$t('Start Date')"
                >
                  <b-form-input
                    type="date"
                    v-model="itemData.dtStart"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12" md="6" class="mb-md-0 mb-2">
                <b-form-group
                  :label="$t('Expired Date')"
                >
                  <b-form-input
                    type="date"
                    v-model="itemData.dtExpired"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12" md="6" lg="6">
                <b-form-group :label="$t('Discount Type')" label-for="orderPromotionDiscountType">
                  <validation-provider #default="{ errors }" rules="required" name="Type">
                    <v-select
                      v-model="itemData.orderPromotionDiscountType"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="orderPromotionDiscountOptions"
                      :reduce="(val) => val.value"
                      :clearable="false"
                      :state="errors.length > 0 ? false:null"
                      input-id="orderPromotionDiscountType"
                      placeholder="Select Discount Type"/>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              
              <b-col cols="12" md="6" lg="6">
                <b-form-group :label="$t('Discount Quantity')" label-for="discountQuantity">
                  <validation-provider #default="{ errors }" rules="required" name="discountQuantity">
                    <b-form-input id="discountQuantity" v-model="itemData.discountQuantity" :state="errors.length > 0 ? false:null" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12" md="6" lg="6">
                <b-form-group :label="$t('Promotion Type')" label-for="orderPromotionType">
                  <validation-provider #default="{ errors }" rules="required" name="Type">
                    <v-select
                      v-model="itemData.orderPromotionType"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="orderPromotionOptions"
                      :reduce="(val) => val.value"
                      :clearable="false"
                      :state="errors.length > 0 ? false:null"
                      input-id="orderPromotionType"
                      placeholder="Select Type"
                      @change="itemData.assignedUserId = null"/>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12" md="3" lg="3" v-if="itemData.orderPromotionType === 2">
                <b-form-group :label="$t('User')" label-for="assignedUserId">
                  <validation-provider #default="{ errors }" rules="required" name="Type">
                    <v-select
                      v-model="itemData.assignedUserId"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="userList"
                      :reduce="(val) => val.id"
                      :clearable="false"
                      :state="errors.length > 0 ? false:null"
                      input-id="assignedUserId"
                      label="userNameAndMail"
                      placeholder="Select User"
                      :loading="loading"
                      @search="fetchUsers"/>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12" md="3" lg="3">
                <b-form-group :label="$t('quantity')" label-for="quantity">
                  <validation-provider #default="{ errors }" rules="required" name="quantity">
                    <b-form-input id="quantity" v-model="itemData.quantity" :state="errors.length > 0 ? false:null" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <save-button 
              :onClickSaveAndClose="saveItem.bind(this, true)"
              :onClickSave="saveItem.bind(this, false)"
              />
          </b-col>
        </b-row>

      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required, min, max,
} from '@validations'
import Ripple from 'vue-ripple-directive'
import SaveButton from '@/components/SaveButton.vue';
import { getCurrentDate, apiToDate } from '@core/utils/filter'
import ApiService from '@/common/api.service'

// Options
import StaticOptions from '@/common/options/StaticOptions';

// Toast
import ToastificationSuccess from '@core/components/toastification/ToastificationSuccess.vue'
// Alert
import AlertService from '@/common/alert.service'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    SaveButton,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      min,
      max,
      userList: [],
      search: '',
      loading: false,

      orderPromotionDiscountOptions: StaticOptions.orderPromotionDiscountOptions,
      orderPromotionOptions: StaticOptions.orderPromotionOptions,
      itemData: {
        id: 0,
        isActive: true,
        dtStart: getCurrentDate(),
        dtExpired: getCurrentDate({ type: 'd', value: 1 }),
        orderPromotionType: 1,
        orderPromotionDiscountType: 1,
        assignedUserId: null,
        discountQuantity: null,
        quantity: null,
      },
    }
  },
  methods: {
    fetchItem() {
      const paramId = this.$router.currentRoute.params.id;
      if (parseInt(paramId, 10) !== 0) {
        this.$store.dispatch('orderPromotionManage/fetch', { id: paramId })
          .then(response => {
            if (response.statusCode === 200) {
              response.result.dtStart = apiToDate(response.result.dtStart)
              response.result.dtExpired = apiToDate(response.result.dtExpired)
              this.itemData = response.result;
            } else {
              AlertService.error(this, response.message)
            }
          })
      }
    },

    saveItem(isClose) {
      if (this.itemData.discountQuantity <= 0) {
        AlertService.error(this, 'Discount Quantity is required')
      }
      if (this.itemData.quantity <= 0) {
        AlertService.error(this, 'Quantity is required')
      }

      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          const query = this.itemData;
          this.$store.dispatch('orderPromotionManage/save', query)
            .then(response => {
              if (response.statusCode === 200) {
                this.$toast({
                  component: ToastificationSuccess,
                  position: 'top-right',
                  props: { text: response.message },
                })

                if (isClose) {
                  this.$router.go(-1);
                }
                response.result.dtStart = apiToDate(response.result.dtStart)
                response.result.dtExpired = apiToDate(response.result.dtExpired)
                this.itemData = response.result;
              } else {
                AlertService.error(this, response.message)
              }
            });
        }
      })
    },

    fetchUsers(search) {
      /* eslint-disable */
      if (search === 'INITIALIZ-SEARCH') {
        search = '';
      } else {
        if (search.length < 3) {
          this.items = [];
          return;
        }
      }

      this.loading = true;

      ApiService.get(`user/selects/${search}`, {}, this)
        .then(response => {
          this.userList = response.result;
          this.loading = false;
        })
    },
  },
  created() {
    this.fetchUsers('INITIALIZ-SEARCH');
    this.fetchItem();
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style scoped>
.source-input {
    box-sizing: border-box;
    margin: 0px;
    outline: 0px;
    padding: 1em;
    height: calc(100% - 3em);
    min-height: 3em;
    width: 100%;
    overflow: auto;
    color: inherit;
    font-size: 0.7em;
    white-space: pre;
}

.banner-img-box {
  height: 90%;
}

.banner-img-box img {
  height: 350px;
  width: auto;
}

.btn-image-update {
  position: absolute !important;
  bottom: 0px;
  right: 15px;
}
</style>
